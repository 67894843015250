import * as React from "react";
import {Table, Thead, Tbody, Tr, Th, Td, chakra, Box, Input, InputGroup, InputLeftElement} from "@chakra-ui/react";
import {Search2Icon, TriangleDownIcon, TriangleUpIcon} from "@chakra-ui/icons";
import {
    useReactTable,
    flexRender,
    getCoreRowModel,
    ColumnDef,
    SortingState,
    getSortedRowModel,
    getFilteredRowModel
} from "@tanstack/react-table";

export type DataTableProps<Data extends object> = {
    data: Data[];
    columns: ColumnDef<Data, any>[];
};

export function DataTable<Data extends object>({
                                                   data,
                                                   columns
                                               }: DataTableProps<Data>) {
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [filtering, setFiltering] = React.useState("")
    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        state: {
            globalFilter: filtering,
            sorting
        },
        getFilteredRowModel: getFilteredRowModel(),
        onGlobalFilterChange: setFiltering,
    });

    return (
        <>
            <Box mb={"2"}>
                <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                        <Search2Icon color='gray.300' />
                    </InputLeftElement>
                    <Input placeholder="Enter search..." type="text" value={filtering} onChange={ e => setFiltering(e.target.value)}/>
                </InputGroup>
            </Box>
            <Box>
                <Table variant='striped' colorScheme="blackAlpha">
                    <Thead bg="blackAlpha.800">
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                                    const meta: any = header.column.columnDef.meta;
                                    return (
                                        <Th
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                            isNumeric={meta?.isNumeric}
                                            textColor="white"
                                        >
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}

                                            <chakra.span pl="4">
                                                {header.column.getIsSorted() ? (
                                                    header.column.getIsSorted() === "desc" ? (
                                                        <TriangleDownIcon aria-label="sorted descending"/>
                                                    ) : (
                                                        <TriangleUpIcon aria-label="sorted ascending"/>
                                                    )
                                                ) : null}
                                            </chakra.span>
                                        </Th>
                                    );
                                })}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map((row) => (
                            <Tr key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                    // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                                    const meta: any = cell.column.columnDef.meta;
                                    return (
                                        <Td key={cell.id} isNumeric={meta?.isNumeric}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
        </>
    );
}
