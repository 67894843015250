import React from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const UnauthorizedPage: React.FC = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate("/"); // Navigate to the previous page
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            textAlign="center"
            p={4}
        >
            <Text fontSize="3xl" fontWeight="bold" mb={4}>
                Access Denied
            </Text>
            <Text fontSize="lg" mb={8}>
                You do not have permission to view this page.
            </Text>
            <Button bg="brand.50"
                _hover={{ bg: "brand.100" }}
                color="white" onClick={handleGoBack}>
                Go Back
            </Button>
        </Box>
    );
};

export default UnauthorizedPage;