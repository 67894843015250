import React, {FC, useRef, useState} from 'react';
import {
    AlertDialog, AlertDialogBody,
    AlertDialogContent, AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay, Button,
    IconButton,
    useDisclosure
} from "@chakra-ui/react";
import {DeleteIcon} from "@chakra-ui/icons";

interface DeleteFN {
    (id: string): Promise<void>;
}

interface DeleteIconAndModalInterface {
    deleteFn?: DeleteFN,
    headerText?: string,
    dialogBody?: string,
    resourceId?: string,
}

const DeleteIconAndModal: FC<DeleteIconAndModalInterface> = ({deleteFn, headerText, dialogBody, resourceId}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef<HTMLButtonElement>(null)

    const [submiting, setSubmiting] = useState(false);

    const deleteBtnClick = async () => {
        setSubmiting(true);

        if (deleteFn && resourceId) {
            await deleteFn(resourceId);
        }

        setSubmiting(false);
        onClose();
    };

    return (
        <>
            <IconButton ml="1" bg="transparent" aria-label="Delete" icon={<DeleteIcon />} onClick={onOpen} />
            <AlertDialog
                isOpen={isOpen}
                onClose={onClose}
                leastDestructiveRef={cancelRef}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {headerText ? headerText : "Delete Customer"}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {dialogBody ? dialogBody : "Are you sure? You can't undo this action afterwards."}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button onClick={onClose} >
                                Cancel
                            </Button>
                            <Button
                                colorScheme='red'
                                onClick={deleteBtnClick}
                                ml={3}
                                isLoading={submiting}
                            >
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
}

export default DeleteIconAndModal;
