import React, {FC, useState} from 'react';
import {
    Box,
    Flex,
    Select,
    Spacer,
    Text
} from "@chakra-ui/react";
import {NavLink} from "react-router-dom";
import {
    ChevronRightIcon,
    WarningTwoIcon
} from "@chakra-ui/icons";
import SidebarProfilePic from "../SidebarProfilePic/SidebarProfilePic";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faToriiGate,
    faUsersGear,
    faGauge,
    faUser,
    faUsers,
    faBookOpen,
    faArrowRightFromBracket,
    faFileInvoiceDollar,
    faEnvelope,
    faTicket
} from '@fortawesome/free-solid-svg-icons'

interface NavItemProps {
    to: string
    icon: any
    text: string
}

const NavItem: FC<NavItemProps> = ({to, icon, text}) => {
    const [active, setActive] = useState(false)
    return (
        <Box>
        <NavLink to={to}
                 style={({ isActive }) =>  {
                     // setActive(isActive); // This makes truble
                     return {};
                 }
        }
        >
            <Flex justifyContent="center" h="12" px="4" alignItems="center" bg={active ? "whitesmoke" : "transparent"}>
                <Box mr="6">
                    {icon}
                </Box>
                <Box>
                    <Text fontSize="xl" textAlign="left">{text}</Text>
                </Box>
                <Spacer />
                <Box>
                    <ChevronRightIcon w="6" h="auto"/>
                </Box>
            </Flex>
        </NavLink>
        </Box>
    );
};

interface SidenavbarProps {}

const Sidenavbar: FC<SidenavbarProps> = () => {
    const [sidebar, setSidebar] = useState(true);
    const showSidebar = () => setSidebar(!sidebar);
    return (

        <Box width={sidebar ? "3xs" : "16"}  h="100vh">
            <nav>
                <ul>
                    {/*<li>*/}
                    {/*    <Flex justifyContent="right">*/}
                    {/*        <Box>*/}
                    {/*            <IconButton*/}
                    {/*                onClick={showSidebar}*/}
                    {/*                icon={sidebar ?  <ArrowLeftIcon /> : <ArrowRightIcon />}*/}
                    {/*                aria-label="Hide menu"*/}
                    {/*                bg="transparent"*/}
                    {/*            />*/}
                    {/*        </Box>*/}
                    {/*    </Flex>*/}
                    {/*</li>*/}
                    <li>
                        <Box
                            pt="4"
                            px="4"
                            hidden={!sidebar}
                        >
                            <Select
                                placeholder='Select gym'
                                bg="white"
                                border={"0"}
                                boxShadow={"xl"}
                            >
                                <option value='option1'>Gym 1</option>
                                <option value='option2'>Gym 2</option>
                                <option value='option3'>Gym 3</option>
                            </Select>
                        </Box>
                    </li>
                    <li>
                        <SidebarProfilePic collabsed={!sidebar}></SidebarProfilePic>
                    </li>
                    <li>
                        <Box>
                                <NavItem to="/" icon={<FontAwesomeIcon icon={faGauge} />} text="Overview"></NavItem>
                        </Box>
                    </li>
                    <li>
                        <Box>
                            <NavItem to="/profile" icon={<FontAwesomeIcon icon={faUser} />} text="Profile"></NavItem>
                        </Box>
                    </li>
                    <li>
                        <Box>
                            <NavItem to="/members" icon={<FontAwesomeIcon icon={faUsers} />} text="Members"></NavItem>
                        </Box>
                    </li>
                    <li>
                        <Box>
                            <NavItem to="/staff" icon={<FontAwesomeIcon icon={faUsersGear} />} text="Staff"></NavItem>
                        </Box>
                    </li>
                    <li>
                        <Box>
                            <NavItem to="/subsidaries" icon={<FontAwesomeIcon icon={faToriiGate} />} text="Subsidiary"></NavItem>
                        </Box>
                    </li>
                    <li>
                        <Box>
                            <NavItem to="/programs" icon={<FontAwesomeIcon icon={faBookOpen} />} text="Programs"></NavItem>
                        </Box>
                    </li>
                    <li>
                        <Box>
                            <NavItem to="/report" icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />} text="Report"></NavItem>
                        </Box>
                    </li>
                    <li>
                        <Box>
                            <NavItem to="/invitations" icon={<FontAwesomeIcon icon={faEnvelope} />} text="Invitations"></NavItem>
                        </Box>
                    </li>
                    <li>
                        <Box>
                            <NavItem to="/subscriptions" icon={<FontAwesomeIcon icon={faTicket} />} text="Subscriptions"></NavItem>
                        </Box>
                    </li>
                    <li>
                        <Box>
                            <NavItem to="/login" icon={<FontAwesomeIcon icon={faArrowRightFromBracket} />} text="Logout"></NavItem>
                        </Box>
                    </li>
                </ul>
            </nav>
        </Box>
    );
}

export default Sidenavbar;
