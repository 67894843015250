import { Button, Flex, FormControl, FormLabel, Select } from '@chakra-ui/react';
import React, { FC, FormEvent, useEffect, useState } from 'react';
import CustomInputField from '../CustomInputField/CustomInputField';
import { Invitation } from '../../Types/Invitation';
import DatePicker from 'react-datepicker';
import { apiService } from '../../Services/ApiService';
import { SubsidiaryData } from '../SubsidaryList/SubsidiaryData';
import { logger } from '../../Logger';

interface OnSubmitCbFn {
    (data: Invitation, subsidiaryId: number): Promise<void>;
}


interface InviteFormProps {
    onSubmitCb: OnSubmitCbFn
};

export const InviteForm: FC<InviteFormProps> = ({ onSubmitCb }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [inviteData, setInviteData] = useState<Invitation>({
        birthday: new Date().toISOString(),
    });
    const [subsidiaryId, setSubsidiaryId] = useState(0);
    const [subsidiaries, setSubsidiaries] = useState<SubsidiaryData[]>([]);


    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            await onSubmitCb(inviteData, subsidiaryId);
        } catch {

        } finally {
            setIsSubmitting(false);
        }
    };

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setInviteData({
            ...inviteData,
            [name]: value,
        });

    };

    useEffect(() => {
        apiService.getAllSubsidiaries()
            .then((response) => {
                setSubsidiaries(response.data);
            })
            .catch(() => {

            });
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSubsidiaryId(Number(event.target.value));
    }

    return (
        <form onSubmit={onSubmit}>
            <Flex flexDirection="column">
                <Flex flexDirection="row" p="4" justifyContent="space-around">
                    <CustomInputField id={"name"} label={"NAME"} input={inviteData?.name} onChange={handleFormChange} isRequired={true} />
                    <CustomInputField id={"surname"} label={"SURNAME"} input={inviteData?.surname} onChange={handleFormChange} isRequired={true} />
                </Flex>
                <Flex p="4">
                    <FormControl>
                        <FormLabel color="#868686" fontWeight="normal">BIRTH DATE</FormLabel>
                        <DatePicker yearDropdownItemNumber={100} showYearDropdown scrollableYearDropdown showIcon selected={inviteData?.birthday === undefined ? new Date() : new Date(inviteData?.birthday)} onChange={date => {
                            setInviteData({
                                ...inviteData,
                                birthday: date?.toISOString(),
                            });
                        }} />
                    </FormControl>
                </Flex>
                <Flex p="4">
                    <CustomInputField id={"address"} label={"ADDRESS"} input={inviteData?.address} onChange={handleFormChange} isRequired={true} />
                    <CustomInputField id={"city"} label={"CITY"} input={inviteData?.city} onChange={handleFormChange} isRequired={true} />
                </Flex>
                <Flex p="4">
                    <CustomInputField id={"zip"} label={"ZIP"} input={inviteData?.zip} onChange={handleFormChange} isRequired={true} />
                    <CustomInputField id={"country"} label={"COUNTRY"} input={inviteData?.country} onChange={handleFormChange} isRequired={true} />
                </Flex>
                <Flex p="4">
                    <CustomInputField type="email" id={"email"} label={"EMAIL ADDRESS"} input={inviteData?.email} onChange={handleFormChange} isRequired={true} />
                    <CustomInputField id={"phone"} label={"PHONE"} input={inviteData?.phone} onChange={handleFormChange} isRequired={true} />
                </Flex>
                <Flex>
                    <Select
                        placeholder='Select gym'
                        bg="white"
                        border={"0"}
                        boxShadow={"xl"}
                        required={true}
                        onChange={handleChange}
                    >
                        {subsidiaries.map(
                            (s) => (
                                <option value={s.id}>{s.title}</option>
                            )
                        )}
                    </Select>
                </Flex>
                <Flex>
                    <Button
                        width="1xs"
                        mt="4"
                        bg="success.50"
                        _hover={{ bg: "success.100" }}
                        color="white"
                        type="submit"
                        isLoading={isSubmitting}
                    >Add</Button>
                </Flex>
            </Flex>
        </form>
    );
};