import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { SubsidiaryData } from './SubsidiaryData';
import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Select, Switch, useDisclosure, useToast } from '@chakra-ui/react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
} from '@chakra-ui/react'
import CustomInputField from '../CustomInputField/CustomInputField';
import { useParams } from 'react-router-dom';
import { apiService } from '../../Services/ApiService';

import { Calendar, momentLocalizer, SlotInfo } from 'react-big-calendar';
import withDragAndDrop, { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import moment from "moment";

import { logger } from '../../Logger';
import DatePicker from 'react-datepicker';
import { ProgramData } from '../../Types/Program';
import { StaffDetails } from '../../Types/StaffDetails';
import { ExtendetEvent } from '../../Types/ExtendedEvent';
import { log } from 'console';

interface SubsidiaryDetailsInterface {

}

const DnDCalendar = withDragAndDrop(Calendar);

export const SubsidiaryDetails: FC<SubsidiaryDetailsInterface> = () => {
    const { id } = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const finalRef = React.useRef(null)
    const localizer = momentLocalizer(moment);
    const toast = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [modalData, setModalData] = useState<ExtendetEvent>({
        isAllDay: false,
        isRecurent: false
    });
    const [allPrograms, setAllPrograms] = useState<ProgramData[]>([]);
    const [staff, setStaff] = useState<StaffDetails[]>();
    const [subsidiaryData, setSubsidiaryData] = useState<SubsidiaryData>({
        location: {
            latitude: 0,
            longitude: 0,
        }
    });

    const [events, setEvents] = useState<ExtendetEvent[]>([])
    const [selectedSlotInfo, setSelectedSlotInfo] = useState<SlotInfo>({
        start: new Date('2024-08-01T10:00:00'),
        end: new Date('2024-08-01T12:00:00'),
        slots: [],
        action: 'select'
    });

    const fetchSchedule = useCallback(() => {
        apiService.getSchedulesForSubsidiary(Number(id)).then((response) => {
            logger.log(`Schedules: ${JSON.stringify(response.data)}`);
            setEvents(response.data.map( (e: ExtendetEvent) => ({
                ...e,
                end: e.endTime !== undefined ? new Date(e.endTime) : undefined,
                start: e.startTime !== undefined ? new Date(e.startTime) : undefined, 
            })));
        }).catch(() => {
            toast({
                title: 'Get subsidiary schedule error.',
                description: '',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        });
    }, [id, setEvents, events]);


    useEffect(() => {
        logger.log(JSON.stringify(events));
    }, [events]);

    const fetchSubsidiaryDetails = useCallback(() => {
        apiService.getSubsidiaryById(Number(id)).then((response) => {
            setSubsidiaryData(response.data);
        }).catch(() => {
            toast({
                title: 'Get subsidiary details error.',
                description: '',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        });
    }, [id]);


    const fetchAllPrograms = useCallback(() => {
        apiService.getAllPrograms().then(
            (response) => {
                setAllPrograms(response.data);
            }
        ).catch(
            () => {
                toast({
                    title: 'Get list of programs error.',
                    description: '',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        );
    }, [setAllPrograms, toast])

    const fetchAllCoaches = useCallback(() => {
        apiService.getAllStaff().then((response)=>{
            setStaff(response.data);
        }).catch(()=> {
            toast({
                title: 'Getting staff list error.',
                description: '',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        });

    }, [setStaff, toast]);

    useEffect(() => {
        fetchSubsidiaryDetails();
        fetchAllPrograms();
        fetchAllCoaches();
        fetchSchedule();
    }, [fetchSubsidiaryDetails, fetchAllPrograms, fetchAllCoaches]);

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);

        apiService.updateSubsidiaryById(Number(id), subsidiaryData)
            .then(() => {
                toast({
                    title: 'Update subsidiary details successful.',
                    description: '',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            })
            .catch(() => {
                toast({
                    title: 'Update subsidiary details error.',
                    description: '',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        if (name.includes('location.')) {
            const localtionField = name.split('.')[1];
            setSubsidiaryData({
                ...subsidiaryData,
                location: {
                    ...subsidiaryData?.location,
                    [localtionField]: value,
                },
            });

        } else {
            setSubsidiaryData({
                ...subsidiaryData,
                [name]: value,
            });
        }
    };

    const handleSelectSlot = useCallback(
        (slotInfo: SlotInfo) => {
            // Pop up custom modal here.
            setSelectedSlotInfo(slotInfo);
            onOpen();
            logger.log(JSON.stringify(slotInfo));
        },
        [onOpen]
    )

    const onEventResize: withDragAndDropProps['onEventResize'] = data => {
        logger.log(`Resize ${JSON.stringify(data)}`);
    }

    const onEventDrop: withDragAndDropProps['onEventDrop'] = data => {
        logger.log(`Drop ${JSON.stringify(data)}`);
    }

    const submitNewEvent = async (e: FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);

        const data: ExtendetEvent = {
            ...modalData,
            title: modalData.titleStr,
            startTime: selectedSlotInfo.start.toISOString(),
            endTime: selectedSlotInfo.end.toISOString()
        }

        logger.log(JSON.stringify(data));
        apiService.addNewSchedule(Number(id), data).then(() => {
            toast({
                title: 'Schedule added successfully.',
                description: '',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            setModalData({
                isAllDay: false,
                isRecurent: false
            });
            fetchSchedule();
        }).catch(()=>{
            toast({
                title: 'Adding schedule error.',
                description: '',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }).finally(() => {
            setIsSubmitting(false);
        })

        logger.log(JSON.stringify(modalData));
        onClose();
    };

    const handleProgramChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setModalData( (prev) => ({
            ...prev,
            programId: Number(event.target.value),
        })
        );
    }

    const handleCoachhange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setModalData( (prev) => ({
            ...prev,
            trainerId: Number(event.target.value),
        })
        );
    }

    const handleScheduleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setModalData({
            ...modalData,
            [name]: value,
        });
    };

    const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setModalData({
            ...modalData,
            [name]: checked,
        });

    };

    return (
        <Flex direction="column" p="16" bg="background.50" h="100%">
            <Box>
                <Heading as='h1' size="2xl">Subsidiaries</Heading>
            </Box>
            <Box>
                <form onSubmit={onSubmit}>
                    <Flex flexDirection="column">
                        <Flex flexDirection="row" p="4" justifyContent="space-around">
                            <CustomInputField id={"title"} label={"TITLE"} input={subsidiaryData?.title} onChange={handleFormChange} />
                            <CustomInputField id={"address"} label={"ADDRESS"} input={subsidiaryData?.address} onChange={handleFormChange} />
                        </Flex>
                        <Flex flexDirection="row" p="4" justifyContent="space-around">
                            <CustomInputField id={"city"} label={"CITY"} input={subsidiaryData?.city} onChange={handleFormChange} />
                            <CustomInputField id={"zip"} label={"ZIP"} type='number' input={subsidiaryData?.zip} onChange={handleFormChange} />

                        </Flex>
                        <Flex flexDirection="row" p="4" justifyContent="space-around">
                            <CustomInputField id={"country"} label={"COUNTRY"} input={subsidiaryData?.country} onChange={handleFormChange} />
                        </Flex>
                        <Flex>
                            <DnDCalendar
                                localizer={localizer}
                                views={{
                                    week: true,
                                }}
                                defaultView="week"
                                selectable
                                events={events}
                                onEventDrop={onEventDrop}
                                onEventResize={onEventResize}
                                onSelectSlot={handleSelectSlot}
                                style={{ height: '50vh', width: '70vw' }}
                            />
                        </Flex>
                        <Flex>
                            <Button
                                width="1xs"
                                mt="4"
                                bg="success.50"
                                _hover={{ bg: "success.100" }}
                                color="white"
                                type="submit"
                                isLoading={isSubmitting}
                            >Update</Button>
                        </Flex>
                    </Flex>
                </form>
            </Box>

            <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
                <form onSubmit={submitNewEvent}>
                    <ModalOverlay />
                    <ModalContent maxW="500">
                        <ModalBody>
                            <Box pl="16" pr="16">
                                <CustomInputField id={"titleStr"} label={"Title"} input={modalData?.titleStr} onChange={handleScheduleFormChange} />
                                <FormControl id='programSelect' mt="4" pr="8">
                                    <FormLabel color="#868686" fontWeight="normal" id='programSelectLabel'>{"Program"}</FormLabel>
                                    <Select
                                        placeholder='Select program'
                                        bg="white"
                                        border={"0"}
                                        boxShadow={"xl"}
                                        required={true}
                                        onChange={handleProgramChange}
                                    >
                                        {
                                            allPrograms.map((p) => (
                                                <option key={p.id} value={p.id}>{p.title}</option>
                                            ))
                                        }                                        
                                    </Select>
                                </FormControl>
                                <FormControl mt="4" pr="8">
                                    <FormLabel color="#868686" fontWeight="normal" id='myLabel'>{"Coach"}</FormLabel>
                                    <Select
                                        id='coachSelect'
                                        placeholder='Select coach'
                                        bg="white"
                                        border={"0"}
                                        boxShadow={"xl"}
                                        required={true}
                                        onChange={handleCoachhange}
                                    >
                                        {
                                            staff?.map((s) => (
                                                <option key={s.id} value={s.id}>{s.profile?.name} {s.profile?.surname}</option>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                            <Flex mt="4"> 
                                <FormControl>
                                    <FormLabel color="#868686" fontWeight="normal">Start</FormLabel>
                                    <DatePicker yearDropdownItemNumber={100} dateFormat="Pp" showTimeSelect showYearDropdown scrollableYearDropdown showIcon selected={selectedSlotInfo?.start} onChange={date => {
                                        if (date !== null) {
                                            setSelectedSlotInfo((prev) => ({
                                                ...prev,
                                                start: date,
                                            }));
                                        }
                                    }} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel color="#868686" fontWeight="normal">End</FormLabel>
                                    <DatePicker yearDropdownItemNumber={100} dateFormat="Pp" showTimeSelect showYearDropdown scrollableYearDropdown showIcon selected={selectedSlotInfo?.end} onChange={date => {
                                        if (date !== null) {
                                            setSelectedSlotInfo((prev) => ({
                                                ...prev,
                                                end: date,
                                            }));
                                        }
                                    }} />
                                </FormControl>
                            </Flex>
                            <Flex mt="4" justifyContent="center" alignItems="center">
                                <FormControl>
                                    <FormLabel color="#868686" fontWeight="normal">{"All day"}</FormLabel>
                                    <Switch
                                        name='isAllDay'
                                        size='md'
                                        colorScheme='brand'
                                        isChecked={modalData.isAllDay}
                                        onChange={handleSwitchChange}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel color="#868686" fontWeight="normal">{"Repeate"}</FormLabel>
                                    <Switch
                                        name='isRecurent'
                                        size='md'
                                        colorScheme='brand'
                                        isChecked={modalData.isRecurent}
                                        onChange={handleSwitchChange}
                                    />
                                </FormControl>
                            </Flex>
                        </ModalBody>

                        <ModalFooter justifyContent="center">
                            <Button
                                width="1xs"
                                bg="success.50"
                                _hover={{ bg: "success.100" }}
                                color="white"
                                type='submit'
                                isLoading={isSubmitting}
                            >ADD</Button>
                            <Button bg="red.500"
                                _hover={{ bg: "red.600" }}
                                color="white"
                                ml={4}
                                onClick={onClose}>
                                CANCLE
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </form>
            </Modal>
        </Flex>
    );
}