import {Outlet} from "react-router-dom"
import Sidenavbar from "../Components/Sidenavbar/Sidenavbar";
import {Box, Flex} from "@chakra-ui/react";
export default function SidebarLayout() {
    return (
        <Box
            minH="100vh"
            h="auto"
            bg={"white"}
            borderColor="#D9D9D9"
        >
            <Flex>
                <Box bg={"white"} minHeight="100vh" borderRightWidth="1px" borderColor="#D9D9D9">
                    <Sidenavbar />
                </Box>
                <Box w="100%" minHeight="100vh">
                    <Outlet />
                </Box>
            </Flex>
        </Box>
    );
}