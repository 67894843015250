import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { Program } from "../../Types/Member";
import { createColumnHelper } from "@tanstack/react-table";
import ActionCell from "../ActionCell/ActionCell";
import { Switch, Badge, Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, FormControl, FormLabel, Heading, Input, InputGroup, InputRightElement, useDisclosure, useToast } from "@chakra-ui/react";
import { DataTable } from "../DataTable/DataTable";
import ReactPaginate from 'react-paginate';
import { OnPageChangeEvent } from '../../Types/OnPageChangeEvent';
import CustomInputField from '../CustomInputField/CustomInputField';
import { ProgramData } from '../../Types/Program';
import { apiService } from '../../Services/ApiService';


interface ProgramsListProps { }

const columnHelper = createColumnHelper<ProgramData>();
const columns = [
    columnHelper.accessor("title", {
        cell: (info) => info.getValue(),
        header: "Program name"
    }),
    columnHelper.accessor("amount", {
        cell: (info) => <><Badge colorScheme="green" >{info.getValue()} EUR</Badge></>,
        header: "Amount"
    }),
    columnHelper.accessor("isSubscription", {
        cell: (info) => <>{info.getValue() === true ? "Monthly" : "Class"}</>,
        header: "Type"
    }),
    columnHelper.accessor("id", {
        cell: (info) => {
            return (
                <>
                    <ActionCell id={info.getValue()} route={`/programs/${info.getValue()}`} />
                </>
            );
        },
        header: "Actions"
    })
];

const ProgramsList: FC<ProgramsListProps> = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const [data, setData] = useState<ProgramData[]>([]);
    const [formData, setFormData] = useState<ProgramData>({
        isSubscription: false,
    });
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize] = useState(10); // Number of items per page

    const fetchPrograms = useCallback(()=>{
        apiService.getPrograms(({page: currentPage, pageSize: pageSize})).then(
            (response) => {
                setData(response.data.content);
                setTotalPages(response.data.totalPages);
            }
        ).catch(() => {
            toast({
                title: 'Fetching programs error.',
                description: '',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        });
    }, [currentPage, pageSize, toast]);

    useEffect(() => {
        fetchPrograms();
    }, [ fetchPrograms ]);

    const handleClick = () => {
        onOpen()
    }

    const handlePageChange = (selected: OnPageChangeEvent) => {
        setCurrentPage(selected.selected);
    };

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        const data: ProgramData = {
            ...formData,
            currency: "EUR",
        }
        apiService.createProgram(data).then(() => {
            toast({
                title: 'Program add successful.',
                description: '',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            setFormData({
                isSubscription: false,
            });
            fetchPrograms();
            onClose();
        }).catch(() => {
            toast({
                title: 'Program add error.',
                description: '',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        })
        .finally(() => {
            setIsSubmitting(false);
        });

        
    };

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

    };

    const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setFormData({
            ...formData,
            [name]: checked,
        });

    };

    return (
        <Flex direction="column" p="16" bg="background.50" h="100%">
            <Box>
                <Heading as='h1' size="2xl">Programs</Heading>
            </Box>
            <Box mb="2" textAlign="right">
                <Button
                    width="1xs"
                    mt="4"
                    bg="success.50"
                    _hover={{ bg: "success.100" }}
                    color="white"
                    onClick={handleClick}
                >Add program</Button>
            </Box>
            <Box>
                <DataTable columns={columns} data={data} />
            </Box>
            <Box>
                <ReactPaginate
                    className='pagination'
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={5}
                    pageCount={totalPages}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null}
                />
            </Box>
            <Drawer onClose={onClose} isOpen={isOpen} size='xl'>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>{`Add Program`}</DrawerHeader>
                    <DrawerBody>
                        <form onSubmit={onSubmit}>
                            <Flex flexDirection="column">
                                <Flex flexDirection="row" p="4" justifyContent="space-around">
                                    <CustomInputField id={"title"} label={"TITLE"} input={formData?.title} onChange={handleFormChange} isRequired={true} />
                                    <FormControl id='amount'>
                                        <FormLabel color="#868686" fontWeight="normal">{"AMOUNT"}</FormLabel>
                                        <InputGroup>
                                            <Input 
                                            name='amount'
                                            type='number'
                                            borderTop="0"
                                            borderRight="0"
                                            borderLeft="0"
                                            borderRadius="0"
                                            borderColor="#F3F2F2"
                                            _focusVisible={ {outline: "none"}}
                                            onChange={handleFormChange}
                                            defaultValue={formData.amount === undefined ? "" : formData.amount}
                                            isRequired
                                            />
                                            <InputRightElement pointerEvents="none">
                                                <span>EUR</span>
                                            </InputRightElement>
                                        </InputGroup>
                                    </FormControl>
                                </Flex>
                                <Flex p="4">
                                    <FormControl>
                                        <FormLabel color="#868686" fontWeight="normal">{"SUBSCRIPTION"}</FormLabel>
                                        <Switch 
                                            name='isSubscription' 
                                            size='md' 
                                            colorScheme='brand'
                                            isChecked={formData.isSubscription}
                                            onChange={handleSwitchChange}
                                        />
                                    </FormControl>
                                </Flex>
                            </Flex>
                            <Button
                                width="1xs"
                                mt="4"
                                bg="success.50"
                                _hover={{ bg: "success.100" }}
                                color="white"
                                type='submit'
                                isLoading={isSubmitting}
                            >Add program</Button>
                        </form>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Flex>
    )
};

export default ProgramsList;
