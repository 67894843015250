import React, {FC, useState} from 'react';
import {Badge, Box, Button, Flex, Heading, HStack, IconButton, Select, VStack} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import {Invoice, Member} from "../../Types/Member";
import {createColumnHelper} from "@tanstack/react-table";
import {CheckCircleIcon, EditIcon} from "@chakra-ui/icons";
import ActionCell from "../ActionCell/ActionCell";
import {DataTable} from "../DataTable/DataTable";


interface ReportProps {}

const data: Invoice[] = [
    {
        id: 1,
        numberOfInvoice: "ST20022",
        createdAt: new Date(),
        amount: 70.0
    },
    {
        id: 2,
        numberOfInvoice: "ST20023",
        createdAt: new Date(),
        paidOn: new Date(),
        amount: 70.0
    },
    {
        id: 4,
        numberOfInvoice: "ST20024",
        createdAt: new Date(),
        paidOn: new Date(),
        amount: 70.0
    },
]

const columnHelper = createColumnHelper<Invoice>();
const columns = [
    columnHelper.accessor("createdAt", {
        cell: (info) => info.getValue().toLocaleString('en-GB'),
        header: "Datum izdaje"
    }),
    columnHelper.accessor("numberOfInvoice", {
        cell: (info) => {
            return (
                <>
                    {info.cell.row.getValue("paidOn") === undefined ? <Badge colorScheme="red">{info.getValue()}</Badge> : <Badge colorScheme="green">{info.getValue()}</Badge> }
                </>
                );
        },
        header: "Št. računa"
    }),
    columnHelper.accessor("amount", {
        cell: (info) => (<>{info.getValue()} EUR</>),
        header: "Znesek"
    }),
    columnHelper.accessor("paidOn", {
        cell: (info) => (<>{info.getValue()?.toLocaleString('en-GB')}</>),
        header: "Datum Plačila"
    }),
    columnHelper.accessor("id", {
        cell: (info) => {
            return (
                <>

                </>
            );
        },
        header: "Actions"
    })
];

const Report: FC<ReportProps> = () => {
    const tmpDate = new Date();
    tmpDate.setDate(1);
    const [dateRange, setDateRange] = useState<[Date | undefined, Date | undefined]>([tmpDate, new Date()]);
    const [startDate, endDate] = dateRange;

    return (
    <Flex direction="column" p="16" bg="background.50" h="100%">
        <Box>
            <Heading as='h1' size="2xl">Report</Heading>
        </Box>
        <Box my="4">
            <HStack alignItems="end">
                <VStack mr="16" alignItems="start">
                    <Heading as="h2" size="xl" mb="4" fontWeight="semibold">Gym</Heading>
                    <Select
                        bg="white"
                        border={"0"}
                        boxShadow={"xl"}
                    >
                        <option value='option1'>All</option>
                        <option value='option2'>LocationOne</option>
                        <option value='option3'>LocationTwo</option>
                        <option value='option4'>LocationThree</option>
                    </Select>
                </VStack>
                <VStack alignItems="start">
                    <Heading as="h2" size="xl" mb="4" fontWeight="semibold">Date range</Heading>
                    <DatePicker
                        selectsRange={true}
                        yearDropdownItemNumber={100}
                        showYearDropdown
                        scrollableYearDropdown
                        showIcon
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                            // setDateRange(update);
                        }}
                        withPortal
                    />
                </VStack>
                <Box >
                    <Button
                        width="1xs"
                        mt="4"
                        bg="success.50"
                        _hover={{ bg:"success.100" }}
                        color="white"
                        type="submit"
                    >Apply</Button>
                </Box>
                <Box >
                    <Button
                        width="1xs"
                        mt="4"
                        bg="facebook.400"
                        _hover={{ bg:"facebook.500" }}
                        color="white"
                        type="submit"
                    >Export XML</Button>
                </Box>
            </HStack>
        </Box>
        <Box>
            <DataTable columns={columns} data={data} />
        </Box>
    </Flex>
)};

export default Report;
