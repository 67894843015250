import React, { FC } from 'react';
import {FormControl, FormLabel, Input} from "@chakra-ui/react";

interface OnChangeFn  {
    (e: React.ChangeEvent<HTMLInputElement>): void;
}

interface CustomInputFieldProps {
    label: string,
    id: string,
    type?: string,
    input?: string | number,
    onChange?: OnChangeFn,
    isRequired?: boolean,
}

const CustomInputField: FC<CustomInputFieldProps> = ({label, id, type, input, onChange, isRequired = false }) => {
    return(
        <FormControl id={id} pr="8">
            <FormLabel color="#868686" fontWeight="normal">{label}</FormLabel>
            <Input
                type={type === undefined ? "text" : type}
                name={id}
                borderTop="0"
                borderRight="0"
                borderLeft="0"
                borderRadius="0"
                borderColor="#F3F2F2"
                _focusVisible={ {outline: "none"}}
                defaultValue={input === undefined ? "" : input}
                onChange={onChange}
                isRequired={isRequired}
            />
        </FormControl>
    );
}

export default CustomInputField;
