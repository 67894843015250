import { Box, Button, Flex, Heading, useToast } from '@chakra-ui/react';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { DataTable } from '../DataTable/DataTable';
import ReactPaginate from 'react-paginate';
import { createColumnHelper } from "@tanstack/react-table";
import { SubsidiaryData } from '../SubsidaryList/SubsidiaryData';
import { OnPageChangeEvent } from '../../Types/OnPageChangeEvent';
import { InvitationData } from '../../Types/Invitation';
import { apiService } from '../../Services/ApiService';

interface InvitationsProprs {

}


const InvitationComponent: FC<InvitationsProprs> = () => {

    const toast = useToast();
    const [data, setData] = useState<InvitationData[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(10);
    const [pageSize] = useState(10); // Number of items per page


    const columnHelper = createColumnHelper<InvitationData>();
    const columns = [
        columnHelper.accessor("email", {
            cell: (info) => info.getValue(),
            header: "Email"
        }),
        columnHelper.accessor("subsidiary.title", {
            cell: (info) => info.getValue(),
            header: "Title"
        }),
        columnHelper.accessor("role.name", {
            cell: (info) => info.getValue(),
            header: "Role"
        }),
        columnHelper.accessor("id", {
            cell: (info) => {
                return (
                    <>
                        <Box flexDirection="row">
                            <Button
                                width="1xs"
                                mt="4"
                                bg="success.50"
                                _hover={{ bg: "success.100" }}
                                color="white"
                                onClick={() => acceptInvitation(info.getValue())}
                            >Accept</Button>
                            <Button
                                width="1xs"
                                mt="4"
                                ml="4"
                                bg="red.500"
                                _hover={{ bg: "red.600" }}
                                color="white"
                                onClick={() => declineInvitation(info.getValue())}
                            >Decline</Button>
                        </Box>
                    </>
                );
            },
            header: "Actions"
        })
    ];

    const fetchMyInvitations = useCallback(() => {
        apiService.getInvitations({page: currentPage, pageSize: pageSize}).then(
            (response) => {
                setData(response.data.content);
                setTotalPages(response.data.totalPages);
            }
        ).catch(() => {
            toast({
                title: 'Fetching invitations error.',
                description: '',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        });
    }, [currentPage, pageSize, toast]);

    const declineInvitation = (invitationId: number) => {
        apiService.declineInvitation({inviteId: invitationId}).then(
            (response) => {
                toast({
                    title: 'Declined invitation.',
                    description: '',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                fetchMyInvitations();
            }
        ).catch(() => {
            toast({
                title: 'Decline invitations error.',
                description: '',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        });
    }

    const acceptInvitation = (invitationId: number) => {
        apiService.acceptInvitation({inviteId: invitationId}).then(
            (response) => {
                toast({
                    title: 'Accepted invitation.',
                    description: '',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                fetchMyInvitations();
            }
        ).catch(() => {
            toast({
                title: 'Accept invitations error.',
                description: '',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        });
    }

    useEffect(() => {
        fetchMyInvitations();
    }, [fetchMyInvitations]);

    const handlePageChange = (selected: OnPageChangeEvent) => {
        setCurrentPage(selected.selected);
    };

    return (
        <Flex direction="column" p="16" bg="background.50" h="100%">
            <Box mb="6">
                <Heading as='h1' size="2xl">My Invitations</Heading>
            </Box>
            <Box>
                <Box>
                    <DataTable columns={columns} data={data} />
                </Box>
                <Box>
                    <ReactPaginate
                        className='pagination'
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageChange}
                        pageRangeDisplayed={5}
                        pageCount={totalPages}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                    />
                </Box>
            </Box>
        </Flex>
    );
}

export default InvitationComponent;